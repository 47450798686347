#communities-main-container {
  display: flex;
  flex-direction: column;
  background-color: #f8f5f2;
  padding: 0 5vw 0 5vw;
  min-height: 100vh;
}

#communities-icons {
  display: flex;
  justify-content: space-between;
}

#communities-add-image {
  width: 40px;
  padding-top: 5vh;
}

#communities-refresh-image {
  width: 40px;
  padding-top: 5vh;
}

#communities-header {
  z-index: 1;
  font-family: 'ClashDisplay-Variable', sans-serif;
  font-weight: 500;
}

.list-no-bullets {
  list-style-type: none;
  padding: 0px 0px 120px 0px;
}

.list-no-bullets li {
  margin-bottom: -40px;
  position: relative;
  padding: 20px 10px;
  border-radius: 10px 10px 20px 20px;
}