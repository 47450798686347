#community-details-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f5f2;
  padding: 0 5vw 0 5vw;
}

#communitydetails-communityname {
  font-family: 'ClashDisplay-Variable', sans-serif;
  font-weight: 600;
}

#communitydetails-communitydescription {
  font-family: 'ClashDisplay-Variable', sans-serif;
  font-weight: 500;
}

#communitydetails-reading-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#communitydetails-members-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#communitydetails-reading-list-items {
  background-color: #f0dab1;
  padding: 5px 15px;
  border-radius: 10px;
  margin: 10px 0;
  border: 2px solid black;
}

#community-details-reading-link {
  text-decoration: none;
  color: #000000;
}

#community-details-icons {
  display: flex;
  justify-content: space-between;
}

#community-details-add-image {
  width: 40px;
  height: 40px;
  padding-top: 5vh;
}

#community-details-refresh-image {
  width: 40px;
  height: 40px;
  padding-top: 5vh;
}