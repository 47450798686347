#profile-main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f8f5f2;
  padding: 10vw 5vw 0 5vw;
}

#profile-header {
  margin-top: 10vh;
  font-family: 'ClashDisplay-Variable', sans-serif;
  font-weight: 500;
}

#profile-info {
  display: flex;
  flex-direction: column;
}

#profile-pic-container {
  position: relative;
  text-align: center;
  margin: 20px 0;
}

#profile-pic-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin: 5vh 0 0 0;
}

#profile-overlay-container {
  position: absolute;
  top: 0;
  
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#profile-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black */
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

#profile-overlay-text {
  color: white;
  font-size: 14px;
  text-align: center;
  font-family: 'ClashDisplay-Variable', sans-serif;
}

#profile-name-tag {
  font-family: 'ClashDisplay-Variable', sans-serif;
  font-weight: 500;
}

#profile-email-tag {
  font-family: 'ClashDisplay-Variable', sans-serif;
  font-weight: 500;
}

#profile-password-tag {
  font-weight: 500;
  font-family: 'ClashDisplay-Variable', sans-serif;
}

#profile-form-button-container {
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  gap: 2vw;
}

#profile-edit-button {
  margin-top: 2vh;
  border: none;
  border-radius: 10px;
  padding: 20px 20px;
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: 500;
  background-color: #f9bc60;
}

#profile-name {
  width: 80vw;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
}

#profile-email {
  width: 80vw;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
}

#profile-password {
  width: 80vw;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
}

#profile-save-changes-button {
  background-color: #078080;
  border: none;
  padding: 10px 40px;
  border-radius: 25px;
  color: white;
}

#profile-cancel-button {
  border: none;
  padding: 10px 40px;
  border-radius: 25px;
}

#profile-logout-container {
  display: flex;
  position: fixed;
  bottom: 15vh;
  left: 0;
  right: 0;
  justify-content: center;
}

#profile-logout-button {
  border: none;
  padding: 20px 40px;
  width: 90%;
  border-radius: 10px;
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: 500;
}
