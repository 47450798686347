#read-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f5f2;
  padding: 0 5vw 0 5vw;
}

#read-header {
  font-family: 'ClashGrotesk-Variable', sans-serif;
  font-weight: 500;
}

#read-form {
  display: flex;
  gap: 2vh;
  flex-direction: column;
  padding-top: 2vh;
  font-family: 'ClashDisplay-Variable', sans-serif;
}

#create-title {
  padding: 15px;
  border-radius: 10px;
  font-family: 'ClashDisplay-Variable', sans-serif;
}

#create-author {
  padding: 15px;
  border-radius: 10px;
  font-family: 'ClashDisplay-Variable', sans-serif;
}

#create-member-input {
  padding: 15px;
  border-radius: 10px;
  font-family: 'ClashDisplay-Variable', sans-serif;
}

#read-button {
  margin-top: 10px;
  margin-bottom: 10vh;
  z-index: 1;
  background-color: #38a856;
  padding: 15px 100px;
  border-radius: 15px;
  text-decoration: none;
  color: white;
  width: 100%;
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: 500;
}

#read-upload-button {
  padding: 15px 100px;
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: 500;
}