.welcome-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  background-color: #f8f5f2;
}

#welcome-design-element-one {
  position: absolute;
  z-index: 0;
  width: 120px;
  right: -100px;
  top: -50px;
  animation: rotate 2s linear 0s infinite;
}
#welcome-design-element-two {
  position: absolute;
  z-index: 0;
  width: 120px;
  left: -100px;
  bottom: -120px;
  animation: rotate 2s linear 0s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  50.01% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

.welcome-heading {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#welcome-heading {
  z-index: 1;
  font-size: 50px;
  color: #f45d48;
  text-shadow: 2px 2px 0px #000000;
  font-family: "ClashGrotesk-Variable", sans-serif;
  font-weight: 500;
  margin: 0px;
}

#welcome-subheading {
  z-index: 1;
  font-size: 15px;
  font-family: "ClashDisplay-Variable", sans-serif;
  font-weight: 500;
  color: #078080;
  margin: 0px;
}

#welcome-buttons {
  z-index: 1;
  display: flex;
  width: 30%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  gap: 1vh;
}

#welcome-login-button {
  z-index: 1;
  padding: 2px 0px;
  border-radius: 45px;
  text-decoration: none;
  color: #078080;
  text-align: center;
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

#welcome-signup-button {
  width: 100%;
  z-index: 1;
  background-color: #078080;
  box-shadow: 2px 2px 0px #000000;
  padding: 2px 0px;
  border-radius: var(--button-radius);
  text-decoration: none;
  color: black;
  text-align: center;
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: 500;
  font-size: 15px;
  font-weight: 500;
}
