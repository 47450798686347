.login-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f5f2;
  padding: 0 20px;
}

#login-title {
  z-index: 1;
  font-size: 30px;
  color: #494949;
  font-family: 'ClashDisplay-Variable', sans-serif;
  font-weight: 500;
}

#login-form {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-radius);
  width: 100%;
  max-width: 500px;
}

#login-email {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
}

#login-password {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
}

#login-email:focus, #login-password:focus {
  outline: none;
}

#login-login-button {
  z-index: 1;
  padding: 10px;
  margin: 20px 0;
  border-radius: var(--button-radius);
  text-decoration: none;
  color: #f8f5f2;
  width: 100%;
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: 500;
  font-size: 15px;
  background-color: #38a856;
  box-shadow: 2px 2px 0px #000000;
}
