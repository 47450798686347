#navbar-outer-container {
  width: 100%;
  position: fixed;
  bottom: 2vh;
  z-index: 10;
}

#navbar-main-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 20px 20px;
  border-radius: 45px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#navbar-button {
  background-color: #ffffff;
  border: none;
  padding: 0 20px;
}
