/* Apply a mobile-first approach */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

body, html {
  overflow-x: hidden;
}

button {
  border: none;
}

input {
  border-width: 0px;
}

@font-face {
  font-family: Headings;
  src: url(./assets/fonts/DMSans-Regular.ttf);
}

@font-face {
  font-family: BebasNeue;
  src: url(./assets/fonts/BebasNeue-Regular.ttf);
}

@font-face {
  font-family: HindMadurai-Medium;
  src: url(./assets/fonts/HindMadurai-Medium.ttf) ;
}

@font-face {
  font-family: ClashGrotesk-Variable;
  src: url(./assets/fonts/ClashGrotesk-Variable.ttf);
}

@font-face {
  font-family: ClashDisplay-Variable;
  src: url(./assets/fonts/ClashDisplay-Variable.ttf);
}

@font-face {
  font-family: Satoshi-Variable;
  src: url(./assets/fonts/Satoshi-Variable.ttf);
}

#primary-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#loading {
  margin: 20px 0;
  width: 25px;
}

/* Styles for mobile devices */
@media (max-width: 768px) {
  /* Add your mobile-specific styles here */
  /* For example: */
  .container {
    width: 100%;
    padding: 10px;
  }
}

/* Styles for larger screens */
@media (min-width: 769px) {
  /* Add your desktop/tablet-specific styles here */
  /* For example: */
  .container {
    width: 768px;
    margin: 0 auto;
    padding: 20px;
  }
}
