#discussion-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f5f2;
  padding: 0 5vw 0 5vw;
  max-width: 100%;
  overflow-x: hidden;
}

#discussion-header {
  color: #a9a9a9;
  font-family: "ClashDisplay-Variable", sans-serif;
  font-weight: 600;
}

#discussion-top-row {
  margin: 5vh 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#discussion-title {
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: bold;
}

#discussion-info-title {
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: bold;
  color: #3e3e3e;
  font-size: 28px;
  margin: -10px 0 0 0;
}

#discussion-info-author {
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: bold;
  color: #3e3e3e;
  font-size: 18px;
  margin: 50px 0 0 0;
}

#discussion-info-start-date {
  font-family: "Satoshi-Variable", sans-serif;
  color: #3e3e3e;
  font-size: small;
  margin: 10px 0 10px 0;
}

#discussion-info-download-link {
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: bold;
  color: #3e3e3e;
  font-size: 18px;
  text-decoration: none;
}

#discussion-info-download-link a {
  text-decoration: none;
  color: #0d1d41;
}

#discussion-book-title {
  font-family: "ClashDisplay-Variable", sans-serif;
  font-weight: 600;
  font-size: 40px;
  margin: 10px 0;
}

#discussion-member-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 1px 5px;
  margin-bottom: 20px;
  padding-right: 20vw;
}

#discussion-assigned-pages {
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: 500;
  font-size: small;
  background-color: #bc2f2d;
  color: rgb(245, 245, 245);
  padding: 5px 10px;
  border-radius: 10px;
}

#discussion-content {
  font-family: "Satoshi-Variable", sans-serif;
}

#discussion-input {
  display: flex;
  flex-direction: column;
  padding-top: 2vh;
}

#discussion-post-title {
  padding: 10px 15px;
  border: none;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
}

#discussion-post-content {
  padding: 10px 15px;
  border: none;
  font-family: "Satoshi-Variable", sans-serif;
}

#discussion-post-button {
  border: none;
  display: flex;
  justify-content: right;
  padding: 10px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  margin-bottom: 10vh;
}

#discussion-post-icon {
  width: 30px;
}

#discussion-info-icon {
  height: 20px;
}

#discussion-info {
  position: absolute;
  border-radius: 10px;
  top: 5vh;
  background-color: #f0dab1;
  padding: 25px 10vw 10px 25px;
  min-height: 80vh;
  width: 90vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#discussion-old-posts {
  display: flex;
  flex-direction: column;
  padding-top: 2vh;
  background-color: #ebebeb98; /* Light grey background */
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
}

#discussion-timestamp,
#discussion-username {
  font-size: 0.8em;
  color: #a9a9a9;
  font-family: "Satoshi-Variable", sans-serif;
}
