#register-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  background-color: #f8f5f2;
  padding: 0 20px;
}

#register-header {
  z-index: 1;
  text-align: center;
  color: #232323;
  font-size: 30px;
  font-family: 'ClashDisplay-Variable', sans-serif;
  font-weight: 500;
}

#register-form {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-radius);
  width: 100%;
  max-width: 500px;
}

#register-name {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
}

#register-email {
  width: 100%;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
}

#register-password {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
}

#register-name:focus, #register-email:focus, #register-password:focus {
  outline: none;
}

#register-register-button {
  margin-top: 20px;
  padding: 10px;
  z-index: 1;
  width: 100%;
  border-radius: var(--button-radius);
  text-decoration: none;
  color: #f8f5f2;
  width: 100%;
  font-family: 'ClashDisplay-Variable', sans-serif;
  font-size: 15px;
  font-weight: 500;
  background-color: #38a856;
  box-shadow: 2px 2px 0px #000000;
}