#create-main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f8f5f2;
  padding: 0 5vw 0 5vw;
}

#create-header {
  margin-top: 4vh;
  font-family: 'ClashDisplay-Variable', sans-serif;
  font-weight: 500;
}

#create-form {
  display: flex;
  gap: 2vh;
  flex-direction: column;
  padding-top: 2vh;
}

#create-communityName {
  padding: 15px;
  border-radius: 10px;
  font-family: "Satoshi-Variable", sans-serif;
}

#create-description {
  padding: 15px;
  border-radius: 10px;
  font-family: "Satoshi-Variable", sans-serif;
}

#create-memberEmails {
  padding: 15px;
  border-radius: 10px;
  font-family: "Satoshi-Variable", sans-serif;
}

#create-button {
  margin-top: 10px;
  z-index: 1;
  background-color: #38a856;
  padding: 15px 100px;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  width: 100%;
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: 500;
}
